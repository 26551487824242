export default Object.freeze({
  //Declaration des Documents Firebase

  CATEGORY: "category",
  COLLECTION: "collection",
  BODY: "body",
  IMAGE: "image",
  IMAGE_PUBLIE: "image_publie",
  TITLE: "title",
  ARCHIVED: "archived",
  DATE: "date",
  DESCRIPTION: "description",
  ISODATE: "isoDate",
  LANGUAGE: "language",
  LIBELLE: "libelle",
  LINK: "link",
  NBRCONSULTED: "nbrconsulted",
  NBRLIKE: "nbrlike",
  NOTIFIETED: "notifieted",
  NOTIFITED: "notifited",
  PUBLISH: "publish",
  SELECTED: "selected",
  SOURCE: "source",
  SOURCENAME: "sourcename",
  UNIQUEID: "uniqueId",
  DELETED: "deleted",
  SOURCEIMAGE: "sourceimage",
  CREATEDBY: "createdby",
  CREATIONDATE: "creationdate",
  DELETEDDATE: "deleteddate",
  UPDATEDBY: "updatedby",
  UPDATEDDATE: "updateddate",
  USERID: "userid",
  DELETEDBY: "deletedby",
  EMAIL: "email",
  FIRSTNAME: "firstname",
  GENDER: "gender",
  LASTNAME: "lastname",
  MYFEED: "myfeed",
  PHONECOUNTRYNAMECODE: "phonecountrynamecode",
  PHONENUMBER: "phonenumber",
  PHOTOURL: "photourl",
  TOKEN: "token",
  USERNAME: "username",
  SEARCHHISTORYLIST: "searchhistorylist",
  SEARCHTEXT: "searchtext",
});
