export default Object.freeze({
  //Declaration des collections Firebase
  CATEGORIES: "categories",
  NOTIFICATIONS: "notifications",
  RSS_FEED: "rss_feed",
  SOURCE_FEED: "source_feed",
  USERS_CONSULTED_NEWS: "users_consulted_news",
  USERS_DETAILS: "users_details",
  USERS_FAVORITE_NEWS: "users_favorite_news",
  USERS_LIKED_NEWS: "users_liked_news",
  USERS_SEARCH_HISTORY: "users_search_history",
});
