import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import firebaseConfig from "./utils/firebaseConfig";
import router from "./router";
import Ads from "vue-google-adsense";
firebase.initializeApp(firebaseConfig);

Vue.use(require("vue-script2"));
Vue.use(Ads.Adsense);
Vue.use(Ads.InArticleAdsense);
Vue.use(Ads.InFeedAdsense);

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
