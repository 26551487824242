import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // Pages
    {
      name: "Home",
      path: "/",
      component: () => import("@/views/Home"),
    },
    {
      name: "DetailNews",
      path: "/detail-news/:uniqueId",
      component: () => import("./components/DetailNews"),
    },
  ],
});

export default router;
