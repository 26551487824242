module.exports = {
  //Declaration des Fonctions générales

  //Amelioration qualité d'une image

  getStringImageQuality(imageUid) {
    if (imageUid.includes("/small")) {
      imageUid = imageUid.replace("/small/", "/");
    } else if (imageUid.includes("thumbnails_small")) {
      imageUid = imageUid.replace("thumbnails_small", "thumbnails_large");
    } else if (imageUid.includes("100x100")) {
      imageUid = imageUid.replace("100x100", "1000x1000");
    } else {
      imageUid = imageUid.replace(".jpg/305", ".jpg/600");
    }

    return imageUid;
  },
  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  },
};
