<template>
  <div>
    <v-app-bar
      class="appBar"
      height="80"
      color="white"
      dense
      fixed
      app
      rounded
      tile
      v-if="$vuetify.breakpoint.lgAndUp"
    >
      <a href="/">
        <img
          class="mr-3"
          height="50"
          :src="require('../assets/logo mercanews.svg')"
          style="cursor: pointer"
        />
      </a>
      <a href="/" style="text-decoration: none; color: black">
        <v-toolbar-title style="cursor: pointer">
          Stay up to date
        </v-toolbar-title>
      </a>
      <v-spacer />

      <v-menu
        :close-on-content-click="false"
        open-on-hover
        bottom
        offset-y
        open-delay="100"
        close-delay="400"
      >
        <template v-slot:activator="{ on }">
          <v-list>
            <v-list-item-group
              v-model="selectedItem"
              color="primary"
              class="d-flex"
            >
              <v-list-item
                v-on="on"
                v-for="(categorie, i) in listeCategories"
                :key="i"
                class="textHover"
                dense
                link
                @mouseover="actualiteCategoryOnHover(categorie.collection)"
              >
                <v-list-item-content>
                  <v-list-item-title link style="cursor: pointer"
                    ><span> {{ categorie.category }} </span></v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </template>

        <v-list>
          <v-container>
            <v-row v-if="this.actualitesCategory" class="ma-3">
              <v-col
                md="3"
                class="pa-3 d-flex flex-column"
                v-for="card in this.actualitesCategory"
                :key="card.title"
                cols="3"
              >
                <v-hover>
                  <template v-slot="{ hover }">
                    <v-card
                      class="elevation-5 flex d-flex flex-column"
                      width="500"
                      max-height="400"
                      @click="redirectToDetail(card)"
                      style="cursor: pointer"
                    >
                      <div>
                        <v-img
                          :src="card.image"
                          v-on:error="card.image = MercanewsLogo"
                          height="200px"
                        >
                          <v-expand-transition>
                            <div
                              v-if="hover"
                              class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal white--text text-center"
                              style="
                                height: 100%;
                                opacity: 0.9 !important;
                                font-size: 14px;
                              "
                              dir="auto"
                            >
                              <p style="margin: 10px">
                                {{ card.description }}
                              </p>
                            </div>
                          </v-expand-transition>
                        </v-img>
                      </div>

                      <v-card-text>
                        <div
                          v-text="card.sourceName"
                          style="font-size: 13px"
                          class="text-align"
                        ></div>
                        <div
                          class="text-align"
                          dir="auto"
                          v-text="card.title"
                          style="color: black; font-size: 13px"
                        ></div>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-hover>
              </v-col>
            </v-row>
          </v-container>
        </v-list>
      </v-menu>

      <v-spacer></v-spacer>
      <!--<v-text-field
        outlined
        clearable
        hide-details
        placeholder="Search"
        rounded
        dense
        single-line
        append-icon="mdi-magnify"
        class="shrink"
        background-color="white"
      >
      </v-text-field>-->
    </v-app-bar>

    <v-app-bar
      height="80"
      dense
      fixed
      app
      color="white"
      rounded
      tile
      v-if="!$vuetify.breakpoint.lgAndUp"
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-spacer></v-spacer>

      <!-- <v-text-field
        outlined
        clearable
        hide-details
        placeholder="Search"
        rounded
        dense
        single-line
        append-icon="mdi-magnify"
        class="shrink"
        background-color="white"
      >
      </v-text-field>-->
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      style="margin-top: 0px"
      temporary
      v-if="!$vuetify.breakpoint.lgAndUp"
    >
      <v-list dense nav>
        <v-list-item>
          <a href="/">
            <v-list-item-avatar class="align-self-center" color="white" contain>
              <v-img src="../assets/logo mercanews.svg" max-height="50" />
            </v-list-item-avatar>
          </a>
          <a href="/" style="text-decoration: none; color: black">
            <v-list-item-content>
              <v-list-item-title> Stay up to date </v-list-item-title>
            </v-list-item-content>
          </a>
        </v-list-item>
      </v-list>

      <v-divider class="mb-2" />
      <v-menu :nudge-width="200" offset-x max-width="350px">
        <template v-slot:activator="{ on, attrs }">
          <v-list nav dense>
            <v-list-item
              class="text-hover"
              link
              v-for="(categorie, i) in listeCategories"
              :key="i"
              v-on="on"
              v-bind="attrs"
              @mousedown="actualiteCategoryOnHover(categorie.collection)"
            >
              <v-list-item-title>
                {{ categorie.category }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </template>
        <v-list style="max-height: 350px" class="overflow-y-auto">
          <v-container>
            <v-row v-if="this.actualitesCategory">
              <v-col
                v-for="card in this.actualitesCategory"
                :key="card.title"
                cols="12"
              >
                <v-card
                  class="mx-auto"
                  max-width="344"
                  outlined
                  @click="redirectToDetail(card)"
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size: 13px"
                        class="text-align"
                      >
                        {{ card.sourceName }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="text-align"
                        dir="auto"
                        style="
                          text-align: justify;
                          color: black;
                          font-size: 13px;
                        "
                      >
                        {{ card.title }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-avatar tile size="80" color="grey">
                      <v-img
                        :src="card.image"
                        v-on:error="card.image = MercanewsLogo"
                      ></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-list>
      </v-menu>
    </v-navigation-drawer>
  </div>
</template>

<script>
import "firebase/compat/firestore";
import db from "../utils/firebaseInit";
import NodesName from "../utils/nodesName";
import NodesFieldName from "../utils/nodesFieldName";
import MercanewsLogo from "../assets/Mercanews.png";
import FunctionUtils from "../utils/functions";

export default {
  data: () => ({
    listeCategories: [],
    drawer: false,
    reveal: false,
    listeActualites: [],
    actualitesCategory: [],
    overlay: false,
    selectedItem: 0,
    hover: false,
    MercanewsLogo: MercanewsLogo,
  }),

  methods: {
    redirectToDetail(item) {
      if (item.sourceName == "Mercanews") {
        let routeData = this.$router.resolve({
          name: "DetailNews",
          params: {
            uniqueId: item.uniqueId,
          },
        });
        window.open(routeData.href, "_blank");
      } else {
        window.open(item.link, "_blank");
      }
    },

    // Recuperation des actualites appartenant a une categorie au survol d'une categorie

    actualiteCategoryOnHover(event) {
      this.actualitesCategory = [];
      var tab = this.listeActualites;
      for (var i = 0; i < tab.length; i++) {
        if (tab[i].category === event) {
          this.actualitesCategory = this.actualitesCategory.concat(tab[i]);
        }
      }
    },

    //Recuperation des categories et les actualités des categories

    async getCategories() {
      await db
        .collection("categories")
        .orderBy("category", "asc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.listeCategories.push({
              category: doc.data().category,
              collection: doc.data().collection,
            });
          });
        })
        .then(() => {
          if (this.listeCategories.length > 0) {
            for (var i = 0; i < this.listeCategories.length; i++) {
              db.collection(NodesName.RSS_FEED)
                .where("libelle", "==", this.listeCategories[i].category)
                .orderBy(NodesFieldName.ISODATE, "desc")
                .limit(4)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    this.listeActualites.push({
                      category: doc.data().category,
                      title: doc.data().title,
                      description:
                        doc.data().description.length > 180
                          ? doc.data().description.substring(0, 180) + "..."
                          : doc.data().description,
                      image: FunctionUtils.getStringImageQuality(
                        doc.data().image
                      ),
                      sourceName: doc.data().sourceName,
                      link: doc.data().link,
                      uniqueId: doc.data().uniqueId,
                    });
                  });
                });
            }
          }
        });
    },
  },

  created() {
    this.getCategories();
  },

  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.textHover:hover {
  background-color: #89cff0;
  border-radius: 50px;
}
.text-hover-root:hover {
  background-color: black;
  border-radius: 50px;
}
.v-text-field {
  width: 220px;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
